import React, { forwardRef } from 'react'
import styled from 'styled-components'

const BlockInner = styled.div`
  max-width: 62rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  width: 100%;
`

const FullWidthBlock = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ children, className }, ref) => (
  <BlockInner className={className} ref={ref}>
    {children}
  </BlockInner>
))

export default FullWidthBlock
